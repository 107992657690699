import * as React from "react"

import { Link } from "gatsby"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faSteam
} from '@fortawesome/free-brands-svg-icons'
import {
    faNewspaper,
    faQuestionCircle,
} from '@fortawesome/free-regular-svg-icons'
import {
    faGamepad
} from '@fortawesome/free-solid-svg-icons'

// Styles
import "../styles/site-header.scss"

// markup
const Header = () => {
    return (
        <div className="site-header">

            <div className="wrapper-wide">

                <div className="content">

                    <div className="logo">
                        <Link to="/">
                            Super Snow Tubes
                        </Link>
                    </div>

                    <nav>

                        <Link to="/">
                            <FontAwesomeIcon icon={faGamepad} className="icon" />
                            Game
                        </Link>
                        <Link to="/faq">
                            <FontAwesomeIcon icon={faQuestionCircle} className="icon" />
                            FAQ
                        </Link>
                        <Link to="/presskit">
                            <FontAwesomeIcon icon={faNewspaper} className="icon" />
                            Press Kit
                        </Link>

                        <a
                            className="button"
                            href="https://store.steampowered.com/app/1515590/Super_Snow_Tubes/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <FontAwesomeIcon icon={faSteam} className="icon" />
                            Steam
                        </a>

                    </nav>

                </div>

            </div>

        </div>
    )
}

export default Header