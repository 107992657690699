import * as React from "react"

import { Link } from "gatsby"

// Styles
import "../styles/site-footer.scss"

// markup
const Footer = () => {
    return (
        <div className="site-footer">

            <div className="wrapper-wide">

                <div className="content">

                    <div className="copy">
                        &copy; 2020-2022 GameDevMix, Super Snow Tubes
                    </div>

                    <nav>
                        <Link to="/">
                            Home
                        </Link>
                        <Link to="/faq">
                            FAQ
                        </Link>
                        <Link to="/presskit">
                            Press Kit
                        </Link>
                    </nav>

                </div>

            </div>

        </div>
    )
}

export default Footer