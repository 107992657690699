import React from 'react';
import Helmet from 'react-helmet';

import { useStaticQuery, graphql } from 'gatsby';

//import favicon from '../../images/favicon.png';

// Useful:
// https://www.emgoto.com/gatsby-metatags/

const Seo = ({
    title,
    description,
    slug, }) => {

    const { site } = useStaticQuery(
        graphql`
                query {
                    site {
                        siteMetadata {
                            title
                            description
                            author
                            siteUrl
                        }
                    }
                }
            `,
    );

    return (

        <Helmet
            htmlAttributes={
                {
                    lang: 'en',
                }
            }
            // link={[
            //     {
            //         rel: 'shortcut icon',
            //         type: 'image/png',
            //         href: `${favicon}`,
            //     },
            // ]}            
            title={title}
            titleTemplate={`%s | ${site.siteMetadata.title}`}
            //titleTemplate={`%s · ${site.siteMetadata.title}`}
            defaultTitle={site.siteMetadata.title}
            meta={[

                // Page Description
                {
                    name: 'description',
                    content: description || site.siteMetadata.description,
                },

                // Facebook (and general)
                // Open Graph meta tags
                // See https://ogp.me/
                {
                    property: 'og:title',
                    content: title || site.siteMetadata.title,
                },
                {
                    property: 'og:description',
                    content: description || site.siteMetadata.description,
                },
                {
                    property: 'og:type',
                    content: 'website',
                },
                {
                    property: 'og:url',
                    content: `${site.siteMetadata.siteUrl}/${slug}`,
                },                

                // Twitter
                // See https://developer.twitter.com/en/docs/twitter-for-websites/cards/guides/getting-started
                {
                    name: 'twitter:title',
                    content: title || site.siteMetadata.title,
                },
                {
                    name: 'twitter:creator',
                    content: '@GameDevMix',
                },
                {
                    name: 'twitter:site',
                    content: '@GameDevMix',
                },
                {
                    name: 'twitter:card',
                    content: 'summary_large_image',
                },
                // For the Twitter image, use either 240x240 or 800x418 as the og:image
                // When choosing an image, you’ll also have to let Twitter know which size you are using. For the large image you’ll need to include this:
                {
                    name: 'twitter:card',
                    content: 'summary_large_image',
                },
                //  And for the small, square image, you’ ll need to add this:
                // {
                //     name: 'twitter:card',
                //     content: 'summary',
                // },

                // Image
                // Is used by both Facebook and Twitter
                {
                    property: 'og:image',
                    content: 'https://supersnowtubes.com/static/f793f246178d969cee5eed4548b47328/2ef5f/sst-illustration.png',
                },                                
            ]}
        />

    );
};

export default Seo;